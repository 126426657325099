import '../css/Search.css'
import { useState, useEffect } from 'react'
import back from '../image/leftBl.png'
import { useNavigate} from 'react-router'
import clock from '../image/clock.png'
import close from '../image/closeGrey.png'
import ListCategories from './ListCategories'
import AllAd from './AllAd'
import saveAdLocalStorage from './saveAdLocalStorage'
import getSaveAds from './getSaveAds'


const Search = props => {
    const navigate = useNavigate()
    const [focus1, setFocus1] = useState(true)
    const [focus2, setFocus2] = useState(false)
    const [filtredData, setFilterData] = useState(null)
    const [filterAds, setFilterAds] = useState(null)
    const [visAllAd, setVisAllAd] = useState(false)
//Записываем сохраненные запросы
    const [arrAsk, setArrAsk] = useState(null)

    const [searchTerm, setSearchTerm] = useState('');

    //Выбираем поисковые запросы
    useEffect(() => {
        const fetchData = async () => {
          const arr = await getSaveAds("searchArr")
        //   console.log("Вытащенные данные из локал стораге!!!", arr);
          if (arr) {
            // console.log("Вытащенные данные из локал стораге", arr);
            setArrAsk(arr)
          }
        };
    
        fetchData();
      }, [props]);
//Функция поиска
    const handleChangeName = (event) => {
        setSearchTerm(event.target.value);
        const filterArr = filtredData

        const filteredArticles = filterArr.filter((ad) => 
        ad.formItems.model.toLowerCase().includes(searchTerm.toLowerCase()) ||
        ad.formItems.brand.toLowerCase().includes(searchTerm.toLowerCase()) ||
        ad.formItems.discription.toLowerCase().includes(searchTerm.toLowerCase()) 
      );
        setFilterAds(filteredArticles)
        // const filteredArticlesObject = Object.fromEntries(filteredArticles);
        // console.log(filteredArticlesObject)
      };

//Фильруем только те что нужно показывать
useEffect(() => {  
    const handleSortAndFilter = () => {
        const allData = props.allData
        if (allData) {
            const filteredData1 = props.allData.filter(item => item.formItems.show === true && item.formItems.clientNotShow === false);
            const sortedData = filteredData1.sort((a, b) => b.formItems.gold - a.formItems.gold);
            // Запись отсортированных данных в state
            setFilterData(sortedData);
          }
        };
      
        handleSortAndFilter();
        // eslint-disable-next-line
      }, [props]);


    const stepBack = () => {
        navigate(-1)
    }

    const btn1 = () => {
        setFocus1(true)
        setFocus2(false)
    }
    const btn2 = () => {
        setFocus2(true)
        setFocus1(false)
    }
    
    //Сортируем чтобы сначала были поисковые объявления
    const goToFilter = (model, brand) => {
    const nameArrToSave = "searchArr"
    const item = `${brand} ${model}`
    saveAdLocalStorage(nameArrToSave, item)

    const lowerCaseSearchTerm = model.toLowerCase();

    const sortedArticles = filterAds.sort((a, b) => {
     const aContainsSearchTerm = a.formItems.model.toLowerCase().includes(lowerCaseSearchTerm) || 
                              a.formItems.brand.toLowerCase().includes(lowerCaseSearchTerm);
     const bContainsSearchTerm = b.formItems.model.toLowerCase().includes(lowerCaseSearchTerm) || 
                              b.formItems.brand.toLowerCase().includes(lowerCaseSearchTerm);

  // Place elements containing the search term first
        if (aContainsSearchTerm && !bContainsSearchTerm) {
            return -1;
        }
        if (!aContainsSearchTerm && bContainsSearchTerm) {
            return 1;
        }
        return 0; // Keep original order if both contain or both don't contain the search term
        });
        const a = [...sortedArticles]
        console.log(a)

        setFilterAds([...sortedArticles])
        setVisAllAd(true)
    }

    return (
        <div className="contSearch">
            <div className="headSearch">
                <img src={back} alt="<" className="leftSearch" onClick={stepBack}/>
                <input 
                className="inputSearch" 
                onChange={handleChangeName} 
                placeholder="Что вы хотите найти?"
                value={searchTerm}
                onFocus={()=>setVisAllAd(false)} />
            </div>
        {visAllAd ?
        <div style={{marginTop:"20px"}}>
        <AllAd filterData={filterAds}/>
        </div>:
        <>
            <div className="underSearch">
                <div className={focus1 ? "under1Focus": "under1"} onClick={btn1}>
                    <p className={focus1 ?"textUnderFocus":"textUnder"}>Недавние</p>
                </div>
                <div className={focus2 ? "under1Focus": "under1"} onClick={btn2}>
                    <p className={focus2 ?"textUnderFocus":"textUnder"}>Категории</p>
                </div>
            </div>
            {focus1 ?
            searchTerm && filterAds ? 
            <div className="contTable">
            {filterAds.slice(0,10).map((ad) => (
                <div key={ad.formItems.art} style={{color:'#000000'}}>
                     <li onClick={()=>goToFilter(ad.formItems.model, ad.formItems.brand)} style={{marginTop:'20px'}} key={ad.formItems.art}>{ad.formItems.brand} {ad.formItems.model}</li>
                 </div>
            ))}
            </div>:
            <div className="contTable">
            <p style={{fontWeight:"bold", fontSize:"17px"}}>Недавние поисковые запросы</p>
            {arrAsk ? arrAsk.map((item, ind) => 
                <div className="eachTable" key={ind}>
                    <img src={clock} alt="o" className="clockImg"/>
                    <div className="stroke">
                        <p>{item}</p>
                        <img src={close} alt="x" />
                    </div>
                </div>
            
            ):
            <p></p>
            }
            </div>
            :
            <div className="contTable" >
                <p style={{fontWeight:"bold", fontSize:"17px"}}>Популярные категории</p>
                <ListCategories/>
            </div>
            }
            </>
}
        </div>
    )
}

export {Search}